.home {
  /* padding-left: 70px; */
  background-color: #202124;
  width: 100%;
  display: flex;
  gap: 50px;
  flex-direction: column;
}
.home h1 , .titlea {
  font-weight: 800;
  font-size: 40px;
  color: #ff8f14;
}
.Levels{
  width: 100%;
  padding-left:15px ;
  color: #ff8f14;
  min-height:100vh ;
}
.levtit{
  font-weight: 800;
  font-size: 40px;
}
.About{
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #ff8800;
  padding-bottom: 20px;
}
.custom-link {
  color: #ff8800; /* اللون البرتقالي */
  text-decoration: none; /* إزالة الخط السفلي */
  font-weight: bold; /* جعل النص عريض */
  padding: 5px 10px;
  border: 2px solid #ff8800; /* إضافة حدود */
  border-radius: 5px; /* جعل الحواف دائرية */
  transition: all 0.3s ease; /* تأثير الحركة عند التفاعل */
}

.custom-link:hover {
  background-color: #ff8800; /* تغيير الخلفية عند المرور */
  color: white; /* تغيير النص إلى أبيض */
}
.Detail{
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
  color: #ff8800;
}
.item22{
  width: 70%;
  min-width: 300px;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 20px;
  /* justify-content: center; */
  height: auto;
  align-items: center;
  text-align: start;
  margin: auto;

}
.item22 img {
  opacity: 0; /* بداية الصورة تكون غير مرئية */
  animation: fadeIn 2s forwards; /* تطبيق الأنيميشن */
}

/* تعريف الحركة */
@keyframes fadeIn {
  0% {
    opacity: 0; /* البداية */
  }
  100% {
    opacity: 1; /* النهاية */
  }
}
.class{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
}
.item{
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  margin: auto;
  text-align: center;
}
.levelcont{
  width: 40%;
  min-width: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  height: auto;
  gap: 20px;
  border: #ff8f14 1px solid;
  background-color: #3f3f3f;
  color: #ff8f14;
  border-radius: 20px;
  text-align: center;
  margin: auto;
}
.Levels h1{
  font-weight: 600;
  font-size: 40px;
  color: #ff8f14;
}
.levelcontent{
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  flex-wrap: wrap;
  gap: 10px;
  align-items: start;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 70px;
  flex-wrap: wrap;
  row-gap: 70px;
}
.profilhome {
  width: 40%;
  min-width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  min-height: 250px;
  height: auto;
  gap: 20px;
  background-color: #3f3f3f;
  color: #ff8f14;
  border-radius: 30px;
}
.status {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.level {
  width: 40%;
  min-width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  min-height: 250px;
  height: auto;
  gap: 20px;
  background-color: #3f3f3f;
  color: #ff8f14;
  border-radius: 30px;
}
.chart {
  width: 40%;
  min-width: 300px;
  /* padding: 20px; */
  display: flex;
  flex-direction: row;
  height: 250px;
  gap: 20px;
  /* background-color: #3f3f3f; */
  color: #ff8f14;
  border-radius: 30px;
}
.Progress {
  margin: auto;
}
.info {
  width: 50%;
  gap : 20px;
  display: flex;
  flex-direction: column;
}
.text1 {
  font-weight: 400;
  font-size: 20px;
  width: 50%;
}
.user {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 0;
  text-align: center;
  align-content: center;
}
.user h2{
  margin-top:5px ;
}
.header1{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

}
.MuiAxis-root {
  stroke: orange !important; /* لون الخط */
}
.levleBut{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 55px;
  border-radius: 13px;
}
.levelitems{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 30px;
  padding-bottom: 20px;
}
.MuiAxis-tickLabel {
  fill: orange !important; /* لون النصوص */
}
.location-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #202124; /* اللون الخلفية الجديد */
  min-height: 100vh;
}

.location-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0 20px ;
  /* margin: 20px 0; */
}

.map-container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.text-container h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ff8800; /* اللون النصي الجديد */
  margin-bottom: 10px;
}

.text-container p {
  font-size: 1.2rem;
  color: #ff8800; /* اللون النصي الجديد */
  line-height: 1.6;
  text-align: justify;
}

@media (max-width: 768px) {
  .location-content {
    width: 100%;
    padding: 10px;
  }

  .map-container iframe {
    height: 300px;
  }

  .text-container h1 {
    font-size: 2rem;
  }

  .text-container p {
    font-size: 1rem;
  }
}
@media (max-width: 1000px) {
  .level {
    flex-direction: column !important;
  }
  .profilhome {
    flex-direction: column !important;
  }
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }
  .home {
    padding-left: 10px !important;
  }
  .text1,
  .info,
  .text1 {
    width: 100%;
  }
  .levelcont{
    width: 88% ;
    margin: auto;
    
  }
}
