.table {
  margin-top: 40px;
  display: flex;
  width: 80% !important;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  min-width: 100%;
}
.butten-add {
  width: 20% !important;
  height: 50px;
  color: white;
  background: linear-gradient(rgb(75, 75, 109), rgb(56, 56, 107));
  border: none;
  border-radius: 15px;
  font-weight: 50px;
  position: relative;
  padding: 0 20px;
}
.butten-add:hover {
  cursor: pointer;
}
.mm{
  width: 16%;
  min-width: 150px;
  border-radius: 10px;
  padding: 10px;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
section .title {
  font-weight: 800;
  font-size: 40px;
  color: #ff8f14;
  margin: 0;
}
.UserTab {
  min-width: 70% !important;
  /* padding-left: 70px; */
  width: 100%;
  background-color: #202124;
}
@media (max-width: 1000) {
  .UserTab {
    width: 100%;
    margin-top: 50px;
    overflow-x: scroll;
    padding: 10px !important; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
