.page {
  height: 100vh;
  background-color: #202124;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containar {
  width: 60%;
  margin: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  color: #ff8f14;
  display: flex;
  flex-direction: row;
}

.form {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}

.form h1 {
  font-weight: bold;
}

.form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: start;
  color: #ff8f14;
}

.image {
  margin: auto;
  width: 50%;
  height: auto; /* Adjust for responsive height */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.image img {
  width: 100%; /* Ensure image fills the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional for rounded edges */
}

button {
  background-color: #ff8f14;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-top: 20px;
}

button:hover {
  background-color: #ff9d33;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

button:active {
  transform: translateY(2px);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .containar {
    flex-direction: column !important;
    width: 90% !important;
  }
  .form {
    width: 100% !important;
  }
  .image {
    display: none; /* Ensure image shows on larger screens */
  }
}
