.page {
  height: 100vh;
  background-color: #202124;
  display: flex;
  justify-content: center;
  align-items: center;
}
.containar1 {
  width: 60%;
  margin: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  color: #ff8f14;
  display: flex;
  flex-direction: row;
}
.form {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 30px;
}
.form h1 {
  font-weight: bold;
}
.form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: start;
  color: #ff8f14;
}
.image {
  margin: auto;
  width: 50%;
  height: 90%;
  align-items: center;
  justify-content: center;
}
button {
  background-color: #ff8f14; /* اللون البرتقالي */
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 12px 20px;
  border: none;
  border-radius: 25px; /* يجعل الحواف مستديرة */
  cursor: pointer;
  transition: all 0.3s ease; /* لإضافة تأثير الانتقال */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* إضافة ظل */
  width: 100%;
  margin-top: 20px;
}

button:hover {
  background-color: #ff9d33; /* تغيير اللون عند التحويم */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* زيادة الظل عند التحويم */
  transform: translateY(-2px); /* إزاحة خفيفة للأعلى */
}

button:active {
  transform: translateY(2px); /* إرجاع للضغط */
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2); /* تقليل الظل */
}

@media (max-width: 768px) {
  .containar1 {
    flex-direction: column !important;
    width: 90% !important;
  }
  .form {
    width: 100% !important;
  }
  .image {
    display: none; /* Ensure image shows on larger screens */
  }
}
