@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
.App {
  text-align: center;
  font-family: "Tajawal", serif;
  font-style: normal;
  margin: 0 !important;
  padding: 0% !important;
  background-color: #202124 !important;
  display: flex !important;
  flex-direction: row ;
  justify-content: space-between;
  text-align: start;
  gap: 50px;
  padding-bottom: 10px;
}
*{
  font-family: "Tajawal";
}
body {
  text-align: left;
  direction: ltr;
  background-color: #202124 !important;
}
::-webkit-scrollbar{
  width: 8px;
}
::-webkit-scrollbar-track{
  background-color: #555;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb{
  background-color: #ff9720 !important;
  border-radius: 50px;
}
/* الأسلوب عند اختيار اللغة العربية (RTL) */
html[dir="rtl"] body {
  text-align: right;
  direction: rtl;
}

@media (max-width: 1000px) {
  .App {
    flex-direction: column !important;
    gap: 20px !important;
  }
  .UserTab {
    width: 97% !important;
    margin: auto;
    padding-left: 0 !important;
    height: 100vh;
  }
  .table {
    margin-top: 0 !important;
  }
  h1 {
    font-size: 30px !important;
  }
  .ali {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
  }
}
