.sidebar{
    min-width: 20% !important;
    max-width: 20% !important;
    min-height: 100vh;
    max-height: 100vh;
    padding: 20px;
    background-color: #202124;
    text-align: center;
    color: #ff8f14;
    display: flex;
    flex-direction: column;
    border-right: #ff8f14 solid 1px;
    border-left: #ff8f14 solid 1px;
    position: sticky;
    top: 0;
}
.sidebar h1{
    font-size: 30px;
    width: 100%;
}
.link{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 20px;
    margin-left:10px ;
}
.link p {
    font-weight: bold;
    font-size: large;
}
